@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&family=Ultra&display=swap");


html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  
}

.app {
  display: flex;
  position: relative;
  height: auto;
  min-height: 1000px;
}


.app .pro-sidebar {
  position: -webkit-sticky;
  min-height: auto;
  position: sticky;
  overflow: auto;
  top: 0;
}

@media screen and (max-width: 0px) {
  .app {
    width: 200%;
  }
}

@media screen and (max-width: 600px) {
  .app {
    width: 250%;
  }
}

@media screen and (max-width: 900px) {
  .app {
    width: 250%;
  }
}

@media screen and (max-width: 1200) {
  .app {
    width: 200%;
  }
}

@media screen and (max-width: 1536) {
  .app {
    width: 200%;
  }
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
